import { useLocation } from 'react-router-dom';
import { Stack, Box } from '@mui/material';
import ConfigForm from "./StrategyEditor/ConfigInput";

import EditorV1 from "./StrategyEditor/EditorV1";
import EditorV2 from "./StrategyEditor/EditorV2";

const EditorPage = () => {
    const location = useLocation();

    const { user_name, strategy_name, editor_version } = location.state;
    console.log("state in EditorPage", user_name, strategy_name, editor_version);
    return (
        <Box>
            <Stack direction="row" spacing={4}>
                {
                    editor_version === 1 ?
                        <EditorV1 user_name={user_name} strategy_name={strategy_name} />
                        : <EditorV2 user_name={user_name} strategy_name={strategy_name} />
                }
                <ConfigForm
                    user_name={user_name}
                    strategy_name={strategy_name}
                />
            </Stack>
        </Box>
    );
};
export default EditorPage;