import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography
} from '@mui/material';
import post from "../connector"
import { useLocation } from 'react-router-dom';




const BacktestsPage = () => {
    const location = useLocation();
    const { user_name, strategy_name } = location.state;
    const [backtests, setBacktests] = useState([]);
    console.log("BacktestTable ", user_name, strategy_name)
    const fetchBacktests = async () => {
        console.log("fetching backtests ", user_name, strategy_name)

        const response = await post('/getBacktests', {
            user_name,
            strategy_name,
        });
        if (response.ok) {
            const backtests = response.data;
            console.log(backtests)
            setBacktests(backtests);
        }

    };
    useEffect(() => {
        fetchBacktests();
    }, []);

    return (
        <TableContainer component={Paper}>
            <Typography variant="h4" gutterBottom component="div" sx={{ p: 2 }}>
                Backtest Results
            </Typography>
            <Table sx={{ minWidth: 700 }} aria-label="backtest table">
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Strategy ID</TableCell>
                        <TableCell>Creation Time</TableCell>
                        <TableCell>Start Date</TableCell>
                        <TableCell>End Date</TableCell>
                        <TableCell>Code Path</TableCell>
                        <TableCell>Run Time (ms)</TableCell>
                        <TableCell>PNL</TableCell>
                        <TableCell>Sharpe Ratio</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {backtests.map((backtest) => (
                        <TableRow key={backtest.id}>
                            <TableCell>{backtest.id}</TableCell>
                            <TableCell>{backtest.strategy_id}</TableCell>
                            <TableCell>{backtest.creation_time}</TableCell>
                            <TableCell>{backtest.start_date}</TableCell>
                            <TableCell>{backtest.end_date}</TableCell>
                            <TableCell>{backtest.run_time_in_ms || 'N/A'}</TableCell>
                            <TableCell>{backtest.pnl !== null ? `$${backtest.pnl.toLocaleString()}` : 'N/A'}</TableCell>
                            <TableCell>{backtest.sharpe_ratio !== null ? backtest.sharpe_ratio.toFixed(2) : 'N/A'}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default BacktestsPage;