import React from 'react';
import { Editor } from "@monaco-editor/react";
import { useState, useEffect } from "react";
import post from "../../connector"
import { Button, Stack, Box, Typography } from '@mui/material';

const CodeEditor = ({ user_name, strategy_name, name, height }) => {

    const [code, setCode] = useState("");
    const [buttonColor, setButtonColor] = useState('success');

    console.log("State in CodeEditor:", user_name, strategy_name, name);

    const onMount = (editor) => {
        editor.focus();
    };
    useEffect(() => {
        loadStrategy();
    }, []);
    const loadStrategy = async () => {
        const response = await post('/getCode', {
            user_name: user_name,
            strategy_name: strategy_name,
            name: name
        });
        if (response.ok) {
            const data = response.data;
            console.log(data);
            setCode(data.code);
        }
    };
    useEffect(() => {
        if (code) {
            setButtonColor('warning'); // Orange
        }
    }, [code]);


    const handleSave = async (event) => {
        console.log("handleSave", user_name, strategy_name);
        event.preventDefault();

        const response = await post('/saveCode', {
            user_name,
            strategy_name,
            name,
            code
        });
        console.log("response ", response);
        if (response.ok) {
            console.log("Request successful", response.data);
            setButtonColor('success');
        }
    }
    return (
        <Stack spacing={2}>
            <Button type="submit" variant="contained" color={buttonColor} sx={{ mt: 2 }} onClick={handleSave}>
                Save
            </Button>

            <Editor
                options={{
                    minimap: {
                        enabled: false,
                    },
                }}
                height={height}
                theme="vs-dark"
                defaultLanguage="rust"
                defaultValue={code}
                onMount={onMount}
                value={code}
                onChange={(code) => setCode(code)}
            />
        </Stack>
    );
};


export default CodeEditor;
