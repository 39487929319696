const post = async (api, json) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    console.log(api, " request:", json)
    try {
        const response = await fetch(apiUrl + api, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(json),
        });
        console.log(api, " response:", response)

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        // const errorMessage = `HTTP response! status: ${response.status}, message: ${data || 'data not found'}`;
        // alert(errorMessage); // Display error using browser's alert
        return {
            ok: response.ok,
            status: response.status,
            data,
        };
    } catch (error) {

        const errorMessage = `Error during fetch: ${error.message}`;
        alert(errorMessage);

        // console.error("Error during fetch:", error);
        return {
            ok: false,
            status: error.name === 'TypeError' ? 'NETWORK_ERROR' : 'UNKNOWN_ERROR',
            error: error.message,
        };
    }
};

export default post;
