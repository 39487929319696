import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import EditorPage from './pages/EditorPage';
// import { GoogleLogin } from '@react-oauth/google';
import HomePage from './pages/HomePage';

import Dashboard from './pages/Dashboard';
import BacktestsPage from './pages/BacktestsPage';


function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/editor" element={<EditorPage />} />
          <Route path="/backtests" element={<BacktestsPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
