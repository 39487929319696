import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import StrategyView from './Dashboard/StrategyView';
import { Container, Typography, TextField, Button, Box, AppBar, Toolbar } from '@mui/material';
import CreateStrategy from './Dashboard/CreateStrategy';


function Dashboard() {
  const location = useLocation();
  const { user_name, email } = location.state;

  console.log('State in Dashboard:', location.state);
  return (<Container>
    <Container maxWidth="md" sx={{ marginTop: '2rem' }}>
      <AppBar position="static" color="primary">
        <Container>
          <Toolbar>
            <Typography variant="h4" component="h1" gutterBottom>
              Welcome, {user_name}
            </Typography>
          </Toolbar>
          <Toolbar>
            <Typography variant="body1" color="inherit">
              Email: {email}
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>

      <CreateStrategy user_name={user_name} />
    </Container>
    <StrategyView user_name={user_name} />
  </Container >
  );
}

export default Dashboard;