import React from 'react';
import { AppBar, Toolbar, Typography, Container, Box, Button, Stack } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google'; // Import Google Icon
import { Navigate, useNavigate } from 'react-router-dom';
import LoginWidget from './Home/Login';

const HomePage = () => {

  const navigate = useNavigate();
  const handleGoogleLogin = () => {
    navigate("/login");
  }

  return (
    <Box sx={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <AppBar position="static" color="primary">
        <Container>
          <Toolbar>
            <Typography variant="h4" component="h1" gutterBottom>
              Algo Trading Platform
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>

      <Container sx={{ flex: 1, mt: 4 }}>
        <Stack direction="column" spacing={4} alignItems="center">
          <Box textAlign="center">
            <Typography variant="h5" component="h2" gutterBottom>
              Welcome to Algo Trading
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Use advanced algorithms to trade effectively and efficiently.
            </Typography>
          </Box>

          <Box textAlign="center">
            <Typography variant="body1" color="textSecondary">
              Get started by logging in with Google
            </Typography>
            <LoginWidget></LoginWidget>
          </Box>
        </Stack>
      </Container>

      <Box sx={{ p: 2, backgroundColor: '#f1f1f1', textAlign: 'center' }}>
        <Typography variant="body2" color="textSecondary">
          © {new Date().getFullYear()} Algo Trading Platform. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default HomePage;
