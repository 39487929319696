import React, { useState } from 'react';
import { Typography, TextField, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import post from "../../connector"

function CreateStrategy({ user_name }) {
    const [strategyName, setStrategyName] = useState('');
    const [editorVersion, setEditorVersion] = useState(1);

    const navigate = useNavigate();

    const handleCreateStrategy = async () => {
        // why below part?
        setStrategyName('');

        const response = await post('/createStrategy', {
            user_name,
            strategy_name: strategyName,
            editor_version: editorVersion
        });
        if (response.ok) {
            const data = response.data;
            console.log("strategy:", data);
            navigate('/editor', { state: { user_name, strategy_name: strategyName, editor_version: editorVersion } });
        }
    };

    return (
        <Box sx={{ maxWidth: '600px', margin: '2rem auto', padding: '2rem', boxShadow: 3, borderRadius: 2 }}>
            <Typography variant="h4" component="h1" gutterBottom align="center">
                Create Strategy
            </Typography>

            <TextField
                variant="outlined"
                label="Strategy Name"
                value={strategyName}
                onChange={(e) => setStrategyName(e.target.value)}
                placeholder="Enter strategy name (letters, numbers, and underscore only)"
                fullWidth
                required
                margin="normal"
            />

            <Typography variant="subtitle1" gutterBottom sx={{ mt: 3, mb: 1 }}>
                Select Editor Version:
            </Typography>

            <Box display="flex" justifyContent="center" gap={2} mb={3}>
                {[1, 2].map((version) => (
                    <Button
                        key={version}
                        variant={editorVersion === version ? 'contained' : 'outlined'}
                        color="primary"
                        onClick={() => setEditorVersion(version)}
                        sx={{ minWidth: '80px' }}
                    >
                        {version}
                    </Button>
                ))}
            </Box>

            <Button
                variant="contained"
                color="primary"
                onClick={handleCreateStrategy}
                fullWidth
                size="large"
            >
                Save Strategy
            </Button>
        </Box>);
}
export default CreateStrategy;