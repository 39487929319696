import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Card, CardContent, CardActions, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import post from "../../connector"

function StrategyView({ user_name }) {
    const [strategies, setStrategies] = useState([]);
    const navigate = useNavigate();
    console.log("user name view", user_name)
    const fetchStrategies = async () => {
        const response = await post('/getStrategies', {
            user_name,
        });
        if (response.ok) {
            setStrategies(response.data);
        }

    };
    useEffect(() => {
        fetchStrategies();
    }, []);

    const navigateToEditor = async (strategy_name, editor_version) => {
        navigate('/editor', { state: { user_name, strategy_name, editor_version } });

    }
    const handleViewBacktests = async (strategy_name) => {
        console.log("handleViewBacktests user name ", user_name, " strategy name: ", strategy_name)

        navigate('/backtests', { state: { user_name, strategy_name } });

    }
    return (
        <Container maxWidth="md" style={{ marginTop: '2rem' }}>
            <Typography variant="h4" component="h1" gutterBottom>
                Strategies
            </Typography>
            <Grid container spacing={3}>
                {strategies.map(strategy => (
                    <Grid item xs={12} sm={6} md={4} key={strategy.id}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5" component="h2">
                                    {strategy.name}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small" color="primary" onClick={() => navigateToEditor(strategy.name, strategy.editor_version)}>
                                    Open Editor
                                </Button>
                                <Button size="small" color="primary" onClick={() => handleViewBacktests(strategy.name)}>
                                    Backtests
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}

export default StrategyView;