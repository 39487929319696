import { Stack, Box, Typography } from '@mui/material';
import CodeEditor from "./CodeEditor";

const EditorV1 = ({ user_name, strategy_name }) => {
    return (
        <Box sx={{ flex: '0 0 70%' }}>
            <Stack spacing={2}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Strategy Name: {strategy_name} version 1
                </Typography>
                <CodeEditor
                    user_name={user_name}
                    strategy_name={strategy_name}
                    name="strategy" height="100vh" />
            </Stack>
        </Box>
    )
}
export default EditorV1;