import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import post from "../../connector"

import { GoogleLogin } from '@react-oauth/google';

function LoginWidget() {
  const navigate = useNavigate();

  const responseMessage = async (response) => {
    console.log('Login Success:', response);
    const decodedToken = jwtDecode(response.credential);
    console.log("decoded token", decodedToken)
    const user_name = decodedToken.name;
    const email = decodedToken.email;
    {
      const response = await post('/login', {
        user_name,
        email
      });
      if (response.ok) {
        console.log("user id :", response.data);
        navigate('/dashboard', { state: { email, user_name } });
      }
    }

  };

  const errorMessage = (error) => {
    console.log(error);
  };
  return (
    <div>
      <h2>Login</h2>
      <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
    </div>
  );
}

export default LoginWidget;

