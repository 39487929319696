
import React, { useState } from 'react';
import { TextField, MenuItem, Button, Box, Typography, Grid, Stack, Paper, Container } from '@mui/material';
import post from "../../connector"

const ConfigForm = ({ user_name, strategy_name }) => {
    const [instrument, setInstrument] = useState('');
    const [exchange, setExchange] = useState('');
    const [startDate, setStartDate] = useState(20240401);
    const [endDate, setEndDate] = useState(20240501);
    const [cash, setCash] = useState(10000000);
    const [result, setResult] = useState({});



    const runBacktest = async (event) => {
        console.log("handle submit on config input")
        event.preventDefault();
        const user_config = { instrument, exchange, "start_date": startDate, "end_date": endDate, cash };
        console.log(user_name);
        console.log(strategy_name);
        const response = await post('/runBacktest', {
            user_name: user_name,
            strategy_name: strategy_name,
            user_config
        });

        if (response.ok) {
            console.log("Request successful", response);
            console.log(response.data)
            setResult(response.data)
        } else {
            console.error("Request failed", response.statusText);
        }


    };

    return (
        <Stack spacing={2}>
            <Box
                component="form"
                sx={{
                    padding: 2,
                    border: '1px solid #ccc',
                    borderRadius: 2,
                    boxShadow: 3,
                    maxWidth: '500px',
                    margin: '0 auto',
                    backgroundColor: '#f9f9f9',

                }}
                onSubmit={runBacktest}
                required
            >
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Config
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            select
                            fullWidth
                            label="Instrument"
                            value={instrument}
                            onChange={(e) => setInstrument(e.target.value)}
                            helperText="Please select an instrument"
                            required
                        >
                            <MenuItem value="SBIN">SBIN</MenuItem>
                            <MenuItem value="RELIANCE">RELIANCE</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            select
                            fullWidth
                            label="Exchange"
                            value={exchange}
                            onChange={(e) => setExchange(e.target.value)}
                            helperText="Please select an exchange"
                            required
                        >
                            <MenuItem value="NSE">NSE</MenuItem>
                            <MenuItem value="BSE">BSE</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Start Date"
                            type="number"
                            value={startDate}
                            onChange={(e) => setStartDate(parseInt(e.target.value, 10))}
                            helperText="Enter start date"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="End Date"
                            type="number"
                            value={endDate}
                            onChange={(e) => setEndDate(parseInt(e.target.value, 10))}
                            helperText="Enter end date"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Cash"
                            type="number"
                            value={cash}
                            onChange={(e) => setCash(parseInt(e.target.value, 10))}
                            helperText="Enter cash amount"
                            required
                        />
                    </Grid>
                </Grid>

                <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                    Run
                </Button>
            </Box>
            <BacktestResult result={result} />
        </Stack>
    );
};


const BacktestResult = ({ result }) => {
    return (
        <Container>
            <Paper elevation={3} style={{ padding: '1rem', marginTop: '1rem' }}>
                <Box>
                    <Grid container spacing={0.1}>
                        {Object.entries(result).map(([key, value]) => (
                            <React.Fragment key={key}>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        {key}:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="body1">{value}</Typography>
                                </Grid>
                            </React.Fragment>
                        ))}
                    </Grid>
                </Box>
            </Paper>
        </Container>
    );
};
export default ConfigForm;