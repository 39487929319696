import { Stack, Box, Typography, Grid, TextField } from '@mui/material';
import CodeEditor from "./CodeEditor";
import React, { useState } from 'react';

const EditorV2 = ({ user_name, strategy_name }) => {
    const [gridItemHeights, setGridItemHeights] = useState([200, 200, 400, 400]);
    const names = ["imports", "struct", "new", "range_bar"];


    return (
        <Box sx={{ flex: '0 0 70%' }}>
            <Stack spacing={2}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Strategy Name: {strategy_name} version 2
                </Typography>
                <Grid container spacing={2} sx={{ mb: 3 }}>
                    {[1, 2, 3, 4].map((gridItem) => (
                        <Grid item xs={6} key={gridItem}>
                            <Box
                                sx={{
                                    height: `${gridItemHeights[gridItem - 1]}px`,
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    borderRadius: 1,
                                }}
                            >
                                <CodeEditor
                                    user_name={user_name}
                                    strategy_name={strategy_name}
                                    name={names[gridItem - 1]} height={gridItemHeights[gridItem - 1]} />
                            </Box>
                        </Grid>
                    ))}
                </Grid>


            </Stack>
        </Box>

    )
}
export default EditorV2;